import React,{ useState} from 'react';
import { useNavigate,useLocation } from "react-router-dom";

const UnivList = ({univs}) =>{
    const url=useNavigate();
    const loc = useLocation();

    const [Userdata,setUserdata] = useState();
    const [accesscode,setaccesscode] = useState()

    const checkOnlyOne = (checkThis) =>{
        const checkboxes = document.getElementsByName('Univ')
        setUserdata({
            UIDX : loc.state.UIDX,
            univ_name:checkThis.value
        }
        )
        for (let i =0; i< checkboxes.length; i++){
            if(checkboxes[i] !== checkThis){
                checkboxes[i].checked = false
            }
        }
    }
    const submitdata =()=>{
        if (Userdata.univ_name==''){
            alert('학교를 선택해주세요')
        }
        else if(Userdata.univ_name=='목포대학교'){
            alert('목포대학교는 신청 기간이 끝났습니다.')
        }
        else{
            url("/selectdept",{state:{user:Userdata}})
        }
    }
    console.log(Userdata)
    return(
        <div className='button_sheet_container'>
            <span className="Dept_sec_txt">본인의 학교를 선택해주세요</span>
            <div className="btn_container">
            {univs.map(univ =>{
                return (
                        <label className="button_box">
                            <input className="btn_style" type="checkbox" name="Univ" onChange={(e) => checkOnlyOne(e.target)} id={univ.Univ_idx} value={univ.Univ_name}/>
                            <label for ={univ.Univ_idx} className="btn_text" key={univ.Univ_idx}>{univ.Univ_name}</label>
                        </label>
                );
            })}
            </div>
            <div className="sign_copy_footer">
            <input type="submit" onClick={submitdata} className="register_txt " value="다음"/>
        </div>
        </div>
    )
}


export default UnivList;