import './css/splash.css';
import Logo from "./img/Main_logo.png";
import React from 'react';

const splash = () =>{
    return(
        <div className="splash_container">
            <img className='main_logo' src={Logo}/>
            <span className='company'>© 2022 Coupling</span>
        </div>
    );
}

export default splash;