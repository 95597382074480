import './css/font.css';
import './css/Home.css';
import Header from './Header.js';
import React from 'react';

import notic2 from './img/cherry_tree+couple.png'

const Home = () =>{


    return (
        <div className="main_container">
            <Header />
            <div className="main_header">
                <div className="window">
                    <img className="main_notic_img" alt="공지사항이 들어갈 자리입니다." src={notic2}/>
                </div>
            </div>
            <div className='main_body'>
                <a href="./selectuniv" className="content_box_red">
                    <span className="content_txt">Coupling 매칭 신청하기</span>
                </a>
                <a href="/proexp" className="content_box">
                    <span className="content_txt">커플링 프로젝트란?</span>
                </a>                
                <a href="./matching" className="content_box">
                    <span className="content_txt">매칭 확인</span>
                </a>
                <a href="./deposit" className="content_box">
                    <span className="content_txt">입금 확인</span>
                </a>
                <a href="./contact"  className="content_box">
                    <span className="content_txt">문의하기</span>
                </a>
            </div>

        </div>
    );
};

export default Home;