import "./css/sign_copy.css"
import { useState} from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import React from 'react';




const Sign_copy=()=>{
    const [User,setUser] = useState({
        UIDX:'',
        univ:'',
        dept:'',
        age:'',
        student_id:'',
        sns_id:'',
        sns_platform:'',
        mbti:'',
        dept_code:'',
        Mbti_match:'',
        phone_ting_match:'',
        bf_ting_match:'',
        want_Mbti:'',
    });
    const [Age,setage] = useState('')
    const [phone_ting_match,setphone_ting_match] = useState('')
    const [bf_ting_match,setbf_ting_match] = useState('')
    const [Mbti_match,setMbti_ting_match] = useState('')
    const [want_Mbti,setwant_mbti] = useState('')

    const loc =useLocation();
    const data =loc.state.user;
    const {age,student_id,sns_id,sns_platform,mbti} = User;
    console.log(User)
    const onChange = (e)=>{
        const {value,name} = e.target;
        if (name=='age'){
            if (isNaN(e.target.value)){
                alert('숫자를 입력해주세요')
                setage('')
            }
            else{
                setage(value)
                if (value.length>3){
                    setage('')
                    alert('최대 3자까지 입력할 수 있습니다.')
                }
            }  
        }
        if (name=='Mbti_match'){
            if (isNaN(e.target.value)){
                alert('숫자를 입력해주세요')
                setMbti_ting_match('')
            }
            else{
                setMbti_ting_match(value)
                if (value.length>3){
                    setMbti_ting_match('')
                    alert('최대 1자까지 입력할 수 있습니다.')
                }
                else if(value>1){
                    setMbti_ting_match('')
                    alert('1이상의 숫자는 입력할 수 없습니다.')
                }
            }  
        }
        if (name=='phone_ting_match'){
            if (isNaN(e.target.value)){
                alert('숫자를 입력해주세요')
                setphone_ting_match('')
            }
            else{
                setphone_ting_match(value)
                if (value.length>3){
                    setphone_ting_match('')
                    alert('최대 1자까지 입력할 수 있습니다.')
                }
                else if(value>3){
                    setphone_ting_match('')
                    alert('3이상의 숫자는 입력할 수 없습니다.')
                }
            }  
        }
        if (name=='bf_ting_match'){
            if (isNaN(e.target.value)){
                alert('숫자를 입력해주세요')
                setbf_ting_match('')
            }
            else{
                setbf_ting_match(value)
                if (value.length>3){
                    setbf_ting_match('')
                    alert('최대 1자까지 입력할 수 있습니다.')
                }
                else if(value>3){
                    setbf_ting_match('')
                    alert('3이상의 숫자는 입력할 수 없습니다.')
                }
            }  
        }
        setUser({
            ...User,
            [name]:value
        })
    }

    const selectList = ["카카오","인스타"];
    const [Selected,setSelected] = useState("카카오");

    const handleSelect = (e) =>{
        setSelected(e.target.value);
    };
    const params = new URLSearchParams(window.location.search)
    User.sns_platform=Selected;
    const url=useNavigate();
    const submitdata =()=>{
        const m_value = User.mbti.toUpperCase()
        const w_m_value = User.want_Mbti.toUpperCase()
        if (User.sns_id == ''|User.sns_platform==''|User.want_Mbti){
            alert('항목을 모두 입력해주세요')
        }else{
        if (m_value==='ENFP'||m_value==='ENFJ'||m_value==='ENTP'||m_value==='ENTJ'||m_value==='ESFP'||m_value==='ESFJ'||m_value==='ESTP'||m_value==='ESTJ'||m_value==='INFP'||m_value==='INFJ'||m_value==='INTP'||m_value==='INTJ'||m_value==='ISFP'||m_value==='ISFJ'||m_value==='ISTP'||m_value==='ISTJ'){    
            if (w_m_value==='ENFP'||w_m_value==='ENFJ'||w_m_value==='ENTP'||w_m_value==='ENTJ'||w_m_value==='ESFP'||w_m_value==='ESFJ'||w_m_value==='ESTP'||w_m_value==='ESTJ'||w_m_value==='INFP'||w_m_value==='INFJ'||w_m_value==='INTP'||w_m_value==='INTJ'||w_m_value==='ISFP'||w_m_value==='ISFJ'||w_m_value==='ISTP'||w_m_value==='ISTJ'){
                url("../sign_finish",{state:{user:User}})    
            }else{
                alert('희망하시는 mbti를 다시 입력해주세요 ex)ENFP')
            }
        }
        else{
            alert('mbti를 다시 입력해주세요 ex)ENFP')
        }
    }
        
    }
    User.univ = data.univ;
    User.dept = data.dept;
    User.dept_code = data.dept_code;
    User.gender = data.gender;
    User.age = data.age;
    User.student_id = data.student_id;
    User.name = data.name;
    User.phone = data.phone;
    User.UIDX = data.UIDX;
    return(
        <div className="sign_copy_container">
            <div className="sign_copy_header">
                <span className="sign_text">회원님의 추가 정보를 입력해주세요</span>
            </div>
            <div className="input_container">
                <div className="input_main_container">
                    <div className="profile_text">
                        <span>SNS 아이디를 입력해주세요 </span>
                        <select  className="SNS_Select_Box" onChange={handleSelect} value={Selected}>               
                            {selectList.map((item)=>(
                                <option value={item} key={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <input className="input_box" onChange={onChange} name="sns_id" />
                </div>
                <div className="input_main_container">
                    <span className="profile_text">본인의 MBTI를 입력해주세요</span>
                    <input className="input_box" onChange={onChange} name="mbti" />
                </div>
                <div className="input_main_container">
                    <span className="profile_text">MBTI 매칭시 희망하시는 MBTI를 입력해주세요</span>
                    <input className="input_box" onChange={onChange} name="want_Mbti" />
                </div>
            </div>
        <div className="sign_copy_footer">
            <input type="submit" onClick={submitdata} className="register_txt " value="가입하기"/>
        </div>
        </div>
    );
}

export default Sign_copy;