import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from './auth.js';

function RequireAuth ({children}){
    const auth = '1';
    if (!auth){
        return <Navigate to='/login'/>;
    }
    return children;
}

export default RequireAuth;