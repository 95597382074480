import './css/splash.css';
import Logo from "./img/Main_logo.png";
import axios from 'axios';
import React from 'react';
import {useEffect} from 'react';


const Splash = () =>{
    useEffect(()=>{
        axios.post('http://localhost:8000/testregist/')
        .then((res)=>{
            console.log(res.data)
        })
    })
    return(
        <div className="splash_container">
            <img className='main_logo' src={Logo}/>
            <span className='company'>© 2022 Coupling</span>
        </div>
    );
}

export default Splash;