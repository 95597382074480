import "./css/sign_finish.css"
import './css/background.css'
import Profile from './img/profile.png'
import axios from 'axios';
import { useNavigate,useLocation } from "react-router-dom";
import {useState,useEffect} from 'react';
import React from 'react';

const Sign_finish=()=>{
    const loc =useLocation();
    const data =loc.state.user;    
    const [User,setUser]= useState([]);
    console.log(data)
    useEffect(()=>{
        
        axios.post('http://localhost:8000/register/',data)
        .then((res)=>{
            setUser(res.data)            
        })

    })
    const url=useNavigate();
    const submitdata =()=>{
        url("../",{state:{user:User[0]}})    
    }

    return(
        <div className="copy_finish_container">
            <div className="background_top_15"></div>
            <div className="background_mid_55 copy_finish_mid">
                <div className="profile_box">
                        <img className="profile_img" alt="profile_img" src={Profile}/>
                        <span className="text1">가입완료!</span>
                </div>
            </div>
       
                
            <div className="sign_copy_footer">
                <input type="submit" onClick={submitdata} className="register_txt " value="계속하기"/>
            </div>
            
        </div>
    );
}

export default Sign_finish;