import './css/login.css'
import Kakaologin_btn from './img/kakao_login.png';
import {KAKAO_AUTH_URL} from './OAuth';
import React from 'react';



const login=()=>{
    return(
        <div className="login_container">
            <div className="oval_1"></div>
            <div className="oval_2"></div>
            <div className="oval_3"></div>
            <div className="catchprize">
                <span>대학생 친구 만들기<br/> 프로젝트</span>
                <span className="dancing_script">Coupling</span>
            </div> 
            <div className="login_footer">
                <div className='kakao_login'>
                    <a href={KAKAO_AUTH_URL}>
                        <img className="kakao_login_font" alt="kakao_login_alt" src={Kakaologin_btn} />
                    </a>
                </div>
            </div>
        </div>
    );
    
}
export default login;