import React from 'react';
import './css/NotFound.css';
import './css/font.css';

const NotFound=()=>{
    return(
        <div className="NotFound_Container">
            <span className='dancing_script notfound_text'>
            Coupling은 현재 PC 버전을 지원하지 않습니다.
            </span>
            <span className='dancing_script notfound_text'>모바일로 접속해주세요</span>
        </div>
    );
};

export default NotFound;