import {useState} from 'react';
import {Route,Routes} from 'react-router-dom'
import React from 'react';
import Main from './component/Home.js';
//import Sign_copy from './component/sign_copy.js';
import Splash from './component/splash.js';
import Login from './component/login.js';
import Sign_finish from './component/sign_finish';
import NotFound from './component/NotFound.js';
import NotFoundPC from './component/NotFound_PC.js';
import Selectuniv from './component/univ';
import Dept from './component/dept';
import RequireAuth from './component/RequireAuth';
import Sign_copy from './component/sign_copy_userinfo';
import Sign_copy_detail from './component/sign_copy_userinfo_detail';
import Test from './component/test';
import Home from './component/Home';
import './App.css';
import './component/css/reset.css';
import {BrowserView,MobileView} from "react-device-detect";
import CheckUser from './component/CheckUser';


function App() {
  const [user,setUser] = useState(null);
  const authenticated = user !=null;
  

  return (
    <div>
    <BrowserView>
      <Routes>
        <Route path={"*"} element={<NotFoundPC />}/>
      </Routes>
    </BrowserView>
    <MobileView>
    <Routes>
        <Route path="/splash" element={<Splash />}/>
        <Route path="/" element={
          <RequireAuth>
            <Main/>
          </RequireAuth>
        }/>
        <Route path="/login" element={<Login />}/>
        <Route path="/sign_finish" element={<Sign_finish />}/>
        <Route path="/selectuniv" element={<Selectuniv />}/>
        <Route path="/selectdept" element={<Dept />}/>
        <Route path ="/sign_up" element={<Sign_copy/>}/>
        <Route path ="/sign_up_detail" element={<Sign_copy_detail/>}/>
        <Route path ="/sign_finish" element={<Sign_finish/>}/>
        <Route path="/CheckUser" element= {<CheckUser/>}/>
        <Route path="/test" element = {<Test/>}/>
        <Route path="/home" element = {<Home/>}/>
        <Route path={"*"} element={<NotFound />}/>
    </Routes>
    </MobileView>
    </div>
  );
}

export default App;

