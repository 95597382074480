import './css/button_sheet.css'
import {useState,useEffect} from 'react';
import axios from 'axios';
import Dept_list from '../pages/dept_list';
import { useLocation } from "react-router-dom";
import React from 'react';


const Dept= ()=>{
    const [dept,setdept] = useState([]);
    const loc =useLocation();
    const data =loc.state.user;
    useEffect(()=>{
        if (dept===""){
        axios.get('http://localhost:8000/dept/',data)
        .then((response) => {
            setdept(response.data);
        });
    }
    },[]);

    return(
            <Dept_list depts = {dept} />
    );
}
export default Dept;