import React,{useState} from 'react';
import { useLocation,useNavigate } from "react-router-dom";

const DeptList = ({depts}) =>{
    const [Userdata,setUserdata] = useState([]);
    const [UserInput,setUserInput] = useState("");
    const loc =useLocation();
    const data =loc.state.user;
    
    const filtered = depts.filter((itemList)=>{
        return itemList.ud_name.includes(UserInput);
    })

    

    const checkOnlyOne = (checkThis) =>{        
        const checkboxes = document.getElementsByName('Dept')
        setUserdata({
            Univ_name:data.univ_name,
            UIDX:data.UIDX,
            Dept:checkThis.value,
            Dept_code:checkThis.id
        })
        for (let i =0; i< checkboxes.length; i++){
            if(checkboxes[i] !== checkThis){
                checkboxes[i].checked = false
            }
        } 
    }
    const url=useNavigate();
    const submitdata =()=>{
        url("/sign_up",{state:{user:Userdata}})
    }
    const onChangetxt = (e) =>{
        setUserInput(e.target.value)
    }
    return(
        <div className='button_sheet_container'>
            <span className="Dept_sec_txt">본인의 학과를 선택해주세요</span>
            <div className= "search_container">
                <span >검색 : </span>
                <input className= "search_box" onChange={onChangetxt} value={UserInput}/>
            </div>
            
            <div className="btn_container">
            {filtered.map(dept =>{
                return (
                    <div className="button_box">
                        <input className="btn_style" type="checkbox" name="Dept" onChange={(e) => checkOnlyOne(e.target)} id={dept.ud_code} value={dept.ud_name}/>
                        <label for={dept.ud_code} className="btn_text" key={dept.ud_uidx}>{dept.ud_name}</label>
                    </div>
                );
            })}
            </div>
        <div className="sign_copy_footer">
            <input type="submit" onClick={submitdata} className="register_txt " value="다음"/>
        </div>
        </div>
    )
}

export default DeptList;