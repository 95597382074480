import './css/Header.css'
import React from 'react';

const Header=()=>{
    return(
        <div className="header">
            <span className="color_black dancing_script font_16">Coupling</span>
        </div>
    );
}

export default Header;