import { useLocation,useNavigate } from 'react-router';
import {useState,useEffect} from 'react';
import './css/login.css'
import axios from 'axios';
import React from 'react';

const CheckUser=()=>{
    const location = useLocation();
    const url = useNavigate();
    const [Data,setData] =useState({
        kakao_code:'',
    });
    const [Code,setCode] = useState({
        code : location.search.split('=')[1],
    });
    useEffect(()=>{
    axios.post('http://localhost:8000/UserCheck/',Code)
    .then(
        (response)=>{    
            if (response.data['exist'] == 'False'){
                const uidx = response.data['UIDX']
                url("../selectuniv",{state:{UIDX:uidx}});
            }else{
                url("../Home");
            }
    });
    })
    return(
        <div className="login_container">
            <div>test</div>
        </div>
    );
    
}
export default CheckUser;