import "./css/sign_copy.css"
import React from 'react';
import { useState} from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import axios from 'axios';
import Modal from 'react-modal';
import './css/modal.css';

const Sign_copy=()=>{
    const [User,setUser] = useState({
        UIDX :'',
        univ:'',
        dept:'',
        age:'',
        student_id:'',
        sns_id:'',
        sns_platform:'',
        mbti:'',
        dept_code:'',
        gender:'',
        name:'',
        phone:'',
    });
    
    const [modalIsOpen,setModalIsOpen] = useState(false);
    const [Age,setage] = useState('')
    const [check,setcheck] = useState('')
    const loc =useLocation();
    const data =loc.state.user;
    const {age,student_id,sns_id,sns_platform,mbti,name,phone} = User;
    const onChange = (e)=>{
        const {value,name} = e.target;
        if (name=='age'){
            if (isNaN(e.target.value)){
                alert('숫자를 입력해주세요')
                setage('')
            }
            else{
                setage(value)
                if (value.length>3){
                    setage('')
                    alert('최대 3자까지 입력할 수 있습니다.')
                }
            }  
        }
        setUser({
            ...User,
            [name]:value
        })
    }

    const selectList = ["카카오","인스타"];
    const [Selected,setSelected] = useState("카카오");

    const params = new URLSearchParams(window.location.search)
    User.sns_platform=Selected;
    const url=useNavigate();
    
    const submitdata =()=>{  
        axios.post('https://coupling.events/regist_check/',User)
        .then((res)=>{
            setcheck(res.data)            
        })
        if (User.name == ''|User.age==''|User.phone==''|User.gender==''){
            alert('항목을 모두 입력해주세요')
        }
        else{
            if (check=='True'){
                alert('해당 학번 및 전화번호로 이미 가입 기록이 있습니다.')
            }
            else{
                url("../sign_up_detail",{state:{user:User}})   
            }
        }
    }
    const handleClickRadioButton =(e)=>{
        if (e.target.id=='Man'){
            User.gender='1'
        }else if(e.target.id=='Female'){
            User.gender='0'
        }
    }
    console.log(User)

    User.univ = data.Univ_name;
    User.dept = data.Dept;
    User.dept_code = data.Dept_code;
    User.UIDX = data.UIDX;
    return(
        <div className="sign_copy_container">
            <div className="sign_copy_header">
                <span className="sign_text">회원님의 정보를 입력해주세요</span>
            </div>
            <div className="input_container">
                <div className="input_main_container">
                    <span className="profile_text">학교</span>
                    <input className="input_box white" onChange={onChange} name="univ" value={data.Univ_name}/>
                </div>
                <div className="input_main_container">
                    <span className="profile_text">학과</span>
                    <input className="input_box white" onChange={onChange} name="dept" value={data.Dept}/>
                </div>
                <div className="input_main_container">
                    <span className="profile_text">이름 입력해주세요</span>
                    <input className="input_box" onChange={onChange} name="name" ></input>            
                </div>
                <div className="input_main_container">
                    <span className="profile_text">나이를 입력해주세요</span>
                    <input className="input_box" onChange={onChange} name="age" value={Age}/>
                </div>
                <div className="input_main_container">
                    <span className="profile_text">학번을 입력해주세요</span>
                    <input className="input_box" onChange={onChange} name="student_id"/>
                </div>
                <div className="input_main_container">
                    <span className="profile_text">전화번호를 입력해주세요 ex)01012345678</span>
                    <input className="input_box" onChange={onChange} name="phone"/>
                </div>
                <div className="input_main_container">
                    <span className="profile_text">성별을 선택해주세요</span>
                    <div className="radio_box">
                    <div>
                    <input
                        id="Man"
                        value="Man"
                        name="gender"
                        type="radio"
                        className= "radio_btn"
                        onClick={handleClickRadioButton}
                        /><label className="radio_label" for ="Man">남자</label>
                        </div>
                    <div>
                    <input
                        id="Female"
                        value="Female"
                        name="gender"
                        type="radio"
                        className= "radio_btn"
                        onClick={handleClickRadioButton}
                        /><label className="radio_label" for ="Female">여자</label>
                    </div>
                    </div>

                </div>
            </div>
        <div className="sign_copy_footer">
            <input type="submit" onClick={submitdata} className="register_txt " value="계속하기"/>
        </div>
            <div className="privicy">
                <input className="privicy_txt" type="button" onClick={()=>setModalIsOpen(true)} value="개인정보 처리방침 자세히 알아보기"/>
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                <div className="modal_container">
                    <div className="privacy_container">
                        <span className="privacy_title">수집하는 기본 개인정보 항목</span>
                        <span className="privacy_content">- 필수정보 : 성명,학교, 학과, 학번, 연락처, 성별, MBTI <br/> - 선택 정보 </span>
                    </div>
                    <div className="privacy_container">
                        <span className="privacy_title">개인정보의 수집 및 이용 목적</span>
                        <span className="privacy_content">연결은 지원자의 개인정보를 다음의 목적을 위해서만 활용되며 수집된 개인정보는 베프팅 이외의 목적으로는 이용되지 않습니다.<br/>1. 번호팅(MBTI,베프팅 등) 매칭<br/>2. 지원자에게 매칭 정보 안내(매칭과 관련된 통보 등)<br/>3. coupling에서 제공하는 이벤트</span>
                    </div>
                    <div className="privacy_container">
                        <span className="privacy_title">개인정보 보유 및 이용 기간</span>
                        <span className="privacy_content">해당 학교에서 Coupling 매칭 서비스 종료 후 14일 이후 폐기됩니다.</span>
                    </div>
                    <div className="privacy_container">
                        <span className="privacy_title">개인정보 제공 동의 거부 권리 및 동의 거부 따른 불이익 내용 또는 제한사항</span>
                        <span className="privacy_content">지원자께서는 상기내용 동의를 거부할 권리가 있으며, 동의 거부에 따라서 Coupling 매칭 서비스에 제한이 있음을 알려드립니다. 또한 Coupling 신청과 동시에 개인정보 활용에 동의 됩니다.</span>
                    </div>
                    <div className="privacy_container">
                        <span className="privacy_title">추가 사항</span>
                        <span className="privacy_content">※ 개인정보 제공자가 동의한 내용외의 다른 목적으로 활용하지 않으며, 제공된 개인정보의 이용을 거부하고자 할 때에는 개인정보 관리책임자를 통해 열람, 정정, 삭제를 요구할 수 있음 「개인정보보호법」등 관련 법규에 의거하여 상기 본인은 위와 같이 개인정보 수집 및 활용에 동의함.</span>
                    </div>
                    <div className="privacy_container">
                        <span className="privacy_title">특별 약관</span>
                        <span className="privacy_content">제공해주신 SNS id로 해당 SNS 플랫폼에서 본인을 찾을 수 없거나 정확하지 않은 SNS id를 제공하신 경우 매칭 상대에게 전화번호가 제공됩니다.</span>
                    </div>
                    <input type="submit" onClick={()=> setModalIsOpen(false)} className="privacy_btn" value="닫기"/>
                </div>
            </Modal>
        </div>
    );
}

export default Sign_copy;