import './css/button_sheet.css'
import {useState,useEffect} from 'react';
import axios from 'axios';
import Univ_list from '../pages/univ_list';
import React from 'react';

const Univ=()=>{
    const [univ,setuniv] = useState([]);
    useEffect(()=>{
        if (univ==""){
        axios.get('http://localhost:8000/univ/')
        .then((response) => {
            setuniv(response.data);
        });
    }
    },[]);
    
    return(
        <Univ_list univs = {univ} />
    );
}
export default Univ;