import React from 'react';
import './css/NotFound.css';
import './css/font.css';

const NotFound=()=>{
    return(
        <div className="NotFound_Container">
            <span className='dancing_script'>
            Coupling 에서는 지원하지 않는 페이지입니다.
            </span>
        </div>
    );
};

export default NotFound;